<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">认证审核</h4>
            <div class="row mt-4">
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table :items="tableData" :fields="fields" responsive="sm" per-page=0 :sort-by.sync="sortBy"
                :no-provider-paging=false :sort-desc.sync="sortDesc" :filter="filter">
                <template v-slot:cell(user_img)="data">
                  <span v-html="data.value"></span>
                </template>

                <template v-slot:cell(user_of_true)="data">
                  <el-tag v-if="data.value">是</el-tag>
                  <el-tag v-else type="info">否</el-tag>
                </template>

                <template v-slot:cell(tool)="data">
                  <b-button style="margin-right: 10px;" @click="CheckMccInfo(data.item)" variant="info">审核
                  </b-button>
                </template>

              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <div class="paginationClass">
                      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="page.currentPage" :page-sizes="[10,20, 30, 40, 50]" :page-size="page.pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="page.total">
                      </el-pagination>
                    </div>
                  </ul>
                </div>
              </div>
            </div>



          </div>
        </div>
      </div>
    </div>


    <el-dialog title="认证信息" :visible.sync="outerVisible">
      <el-descriptions class="margin-top" title="认证信息" :column="2" :size="size" border>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            公司名称
          </template>
          {{DealUserInfo.name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-mobile-phone"></i>
            昵称
          </template>
          {{DealUserInfo.nike_name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-location-outline"></i>
            公司Logo
          </template>

          <div class="LogoImg">
            <el-image class="Img" :src="utils.Img_Url+DealUserInfo.img_url"
              :preview-src-list="[utils.Img_Url+DealUserInfo.img_url,utils.Img_Url+DealUserInfo.Business_license_img]">
            </el-image>
          </div>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-location-outline"></i>
            营业执照
          </template>
          <div class="LicenseImg">
            <el-image class="Img" :src="utils.Img_Url+DealUserInfo.Business_license_img"
              :preview-src-list="[utils.Img_Url+DealUserInfo.Business_license_img,utils.Img_Url+DealUserInfo.img_url]">
            </el-image>
          </div>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            联系人
          </template>
          {{DealUserInfo.contacts}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            电话
          </template>
          {{DealUserInfo.contacts_number}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            微信号
          </template>
          {{DealUserInfo.contacts_wx_number}}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            所在地区
          </template>
          {{DealUserInfo.contacts_region}}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            详细地址
          </template>
          {{DealUserInfo.contacts_gis}}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            公司简介
          </template>
          {{DealUserInfo.brief_introduction}}
        </el-descriptions-item>
      </el-descriptions>

      <div style="display: flex;justify-content: center;padding-top: 50px;">
        <el-button type="success" @click="SuccessAudit" style="margin-right:30px;">审核通过</el-button>
        <el-button type="danger" @click="RejectiveAudit">审核未通过</el-button>
      </div>

    </el-dialog>

    <el-dialog title="审核未通过原因" width="30%" :visible.sync="AuditVisible">
      <el-input type="textarea" :rows="5" placeholder="请输入审核失败原因" v-model="RejectiveInfo">
      </el-input>
      <div style="display: flex;justify-content: center;padding-top: 50px;">
        <el-button type="success" @click="SubmitRejectiveAudit" style="margin-right:30px;">确认</el-button>
        <el-button type="danger">取消</el-button>
      </div>

    </el-dialog>

  </Layout>
</template>

<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";
  import utils from "@/utils/index"
  // import { tableData } from "./dataAdvancedtable";
  /**
   * Advanced table component
   */
  export default {
    page: {
      title: "认证审核",
      meta: [{ name: "description", content: appConfig.description }]
    },
    components: { Layout, PageHeader },
    data() {
      return {
        outerVisible: false,
        // 审核失败弹框
        AuditVisible: false,
        // 审核失败原因
        RejectiveInfo: "",
        tableData: '',
        title: "认证审核",
        items: [
          {
            text: "首页",
            href: "/"
          },
          {
            text: "认证审核",
            active: true
          }
        ],
        filter: null,
        // 排序
        sortBy: "go_time",
        sortDesc: true,
        fields: [
          { key: "mobile", label: "手机号" },
          { key: "name", label: "公司名称" },
          {
            key: "start_date", label: "提交时间", sortable: true,
          },
          {
            key: "tool", label: "工具栏"
          },
        ],
        EditRowData: "",
        LogoFile: "",
        page: {
          pageSize: 10,//每页显示条数
          currentPage: 1,//当前页码数
          total: 0,
        },
        Editindex: "",
        DealUserInfo: "",
        utils: ""
      };
    },
    computed: {
    },
    mounted() {
    },
    created() {
      this.utils = utils
      this.CheckParentCompany()

      window.addEventListener("keydown", this.handkeyCode, true); //开启监听键盘按下事件

    },
    methods: {
      // 键盘按下事件
      handkeyCode(e) {
        let key = null;
        if (window.event === undefined) {
          key = e.keyCode;
        } else {
          key = window.event.keyCode;
        }
        if (this.modalShow == true && key === 13) {

        }
      },
      // 删除
      delRows(item,) {
        _API_.ParentCompany({
          name: item,
        }).then((res) => {
          this.CheckParentCompany()
        })
      },
      // 审核
      CheckMccInfo(Item) {
        this.outerVisible = true
        this.DealUserInfo = Item
      },
      // 审核失败
      RejectiveAudit() {
        this.AuditVisible = true
      },
      // 审核失败提交
      SubmitRejectiveAudit() {
        _API_.AuditI_Of({
          id: this.DealUserInfo.id,
          of_tf: 2,
          text: this.RejectiveInfo,
          mobile: this.DealUserInfo.mobile
        }).then((res) => {
          if (res.code == 200) {
            utils.ShowMsg(res.msg, 'success')
          } else {
            utils.ShowMsg(res.msg, 'error')
          }
        })
      },
      // 审核通过
      SuccessAudit() {
        _API_.AuditI_Of({
          id: this.DealUserInfo.id,
          of_tf: 1,
          mobile: this.DealUserInfo.mobile
        }).then((res) => {
          if (res.code == 200) {
            utils.ShowMsg(res.msg, 'success')
          } else {
            utils.ShowMsg(res.msg, 'error')
          }
        })
      },
      // 获取数据
      CheckParentCompany() {
        _API_.AwaitAudit({
          of_tf: 0,
          page: this.page.currentPage,
          size: this.page.pageSize
        }).then((res) => {
          this.tableData = res.data
          this.page.total = res.count
        })
      },
      // 更改Size
      handleSizeChange(size) {
        this.page.currentPage = 1
        this.page.pageSize = size
        this.CheckParentCompany()
      },
      // 更改Page
      handleCurrentChange(page) {
        this.page.currentPage = page
        this.CheckParentCompany()
      }

    }
  };
</script>
<style>
  .el-form-item {
    margin-bottom: 10px
  }

  .Img {
    width: 100%;
    height: 100%;
  }

  .LogoImg {
    width: 150px;
    height: 150px;
    cursor: pointer;
  }

  .LicenseImg {
    width: 250px;
    height: 150px;
    cursor: pointer;
  }
</style>